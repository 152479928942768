import React, { useRef, useCallback, useContext, useState, useEffect, FC } from 'react'
import { useFormik } from 'formik'
import { UfinetButton, UfinetDropzone, UfinetActionButton, UfinetActionButtonHandle } from 'ufinet-web-components';
import { onFormikChanges, IFileUploadData, AuthContext } from 'ufinet-web-functions';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { Translation, useTranslation } from 'utils/translation/Translation';
import { UsePostBulkLoadFile } from 'request/request';
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl'
import { ErrorResponse } from 'types/typesAndValues';


interface Props {
  downLoadTemplate: () => void;
  hideLoadModal: () => void;
}

interface File {
  file: IFileUploadData
}


const BulkLoadForm: FC<Props> = ({ downLoadTemplate, hideLoadModal }) => {

  const intl = useIntl()
  const translate = useTranslation()



  const [file, setFile] = useState()

  const authData = useContext(AuthContext)
  const userEmail = authData.userData?.username

  const actionButtonRef = useRef<UfinetActionButtonHandle>(null)
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {

    if (showMessage) setShowMessage(false)

  }, [showMessage])



  const onChange = useCallback(onFormikChanges, [])



  const cbSuccessUploadBulkFile = (data: any) => {
    toast.success(translate('SENDED'));
    hideLoadModal()
  }



  const { mutate: uploadBulkFile, isError, error: errorMessage } = UsePostBulkLoadFile(cbSuccessUploadBulkFile)




  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${intl.formatMessage({ id: "ERROR.MESSAGE" },)} ${errorResponse.errorCode}`, { toastId: 'Error1' });
      hideLoadModal()
    }
  }

  const dataFormSchema = Yup.object().shape({
    file: Yup.mixed()
      //@ts-ignore
      .test('file required', Translation('ERROR.REQUIRED'), (value) => value.size > 0)
      .test('file type', Translation('ERROR.FILE.EXTENSION'), (value) => {
        //@ts-ignore
        const fileExtension = value?.path?.split('.').pop()
        return fileExtension === undefined || fileExtension.includes(['xlsx']) || fileExtension.includes(['xls'])
      }),
  })



  const formik = useFormik<File>({
    initialValues: {
      file: {
        path: '',
        size: 0,
      },
    },
    validationSchema: dataFormSchema,
    onSubmit: (values: File) => {

      uploadBulkFile(values.file)
      // hideLoadModal && hideLoadModal()
    },
    validateOnChange: false,
    validateOnBlur: false,
  })


  return (
    <>
      {isError && showToastErrMessage()}
      {/* {showMessage && showToasMessage()} */}

      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="row py-10">
            <div className="col-12">
              <UfinetButton
                onClick={downLoadTemplate}
                className="w-100"
                content={Translation('DOWNLOAD.TEMPLATE')}
                icon={faDownload}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <UfinetDropzone
                isDisabled={false}
                onChange={onChange(formik, 'file')}
                value={formik.values.file}
                error={formik.errors.file}
                text={Translation('DROPZONE.UPLOAD_EXCEL.TITLE')}
                accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls'] }}
              />
            </div>
          </div>

          <div className="row py-10">
            <div className="col-12">
              <UfinetButton
                type='submit'
                className="w-100"
                content={Translation('SAVE')}
                icon={faSave}
              />
            </div>
          </div>

          {/* <div className="row">
        <UfinetActionButton
          onClick={downLoadTemplate}
          // ref={actionButtonRef}
          className="mt-10"
          content={Translation('SAVE')}
          icon={faSave}
        />
      </div> */}

          {/* <div className="row">
        <div>
          <UfinetButton
            // onClick={downLoadTemplate}
            className="mt-10"
            content={Translation('SAVE')}
            icon={faSave}
          />
        </div>
      </div> */}

          {/* <UfinetButton
        content={Translation('Descargar')}
        onClick={downLoadTemplate}
      /> */}
        </div>
      </form>
    </>
  )
}

export default BulkLoadForm


