import React, { useState, useContext, useMemo, useCallback, ChangeEvent, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import Button from 'react-bootstrap/Button'
import styled from 'styled-components'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { toast } from 'react-toastify'
import { Form, InputGroup } from 'react-bootstrap';
import { MultipleDeleter, Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal, } from 'ufinet-web-functions'
// import { colsPendingCommisions } from './PendingColData'
import { GoalsTypes } from 'types/GoalsTypes'
import { UsePatchGoals, UseGetBulkLoadTemplate } from '../../request/request'
import { colsGoals } from './GoalsColData'
import { Translation, useTranslation } from 'utils/translation/Translation'
import { Goal } from 'types/goals/goalsTypes'
import BoxTotal from 'lib/box/BoxTotal'


import { UseQueryGetUsers, UseQueryGetData } from '../../request/request'
import BulkLoadForm from './bulk-load/BulkLoadForm'
import { faChessKing } from '@fortawesome/free-solid-svg-icons'
import { ErrorResponse } from 'types/typesAndValues'

interface Objeto {
  period: number | undefined;
  netArc: string | number;
  arc: string | number;
  churnBreakage?: string | number;
}

const GoalsPage = () => {

  const translate = useTranslation()

  // const fileRef = useRef<Blob | null>(null);
  // const [template, setTemplate] = useState(null)

  const authData = useContext(AuthContext)
  const userEmail = authData.userData?.username
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionGoalsPermissions(roles)

  const [selectedValues, setSelectedValues] = useState()
  const [loadModal, showLoadModal, hideModal] = useModal()

  const { data: dataUsers, isLoading: dataUsersLoading, } = UseQueryGetUsers()
  const [showMessage, setShowMessage] = useState(false)

  const [selectedUsername, setSelectedUsername] = useState('')
  const [inputSelect, setInputSelect] = useState({
    year: '',
    username: ''
  });

  const [input, setInput] = useState<Objeto[]>([])

  useEffect(() => {
    // This gonna listen if the username is changing
    if (inputSelect.username !== '') setSelectedUsername(inputSelect.username)
    // This gonna compare the value before with the current value to set the input
    if (inputSelect.username !== selectedUsername) setInput([])
  }, [inputSelect])

  // useEffect(() => {
  //   if (showMessage) setShowMessage(false)
  // }, [showMessage])

  const cbSuccessBulkLoadTemplate = ({ data }: any) => {

  }

  // To get the bulk load template
  const { mutate: bulkLoadTemplate } = UseGetBulkLoadTemplate(cbSuccessBulkLoadTemplate)



  const { data: dataYears, refetch: refetchDataYears } = UseQueryGetData(inputSelect)

  const totalAmount = dataYears && dataYears.length > 0 && dataYears[0].totalGoalsAmount
  const currencyCode = dataYears && dataYears.length > 0 && dataYears[0].currencyCode

  const cbSuccessUsePatchGoals = ({ data }: any) => {
    toast.success(data.message)
    setInput([])
    setTimeout(() => {
      refetchDataYears()
    }, 1000)
    setShowMessage(true)
  }

  const {
    mutate: sendPatchGoals,
    isLoading: loadingPatchGoals,
    isSuccess: successPatchGoals,
    isError,
    error: errorMessage,
  } = UsePatchGoals(cbSuccessUsePatchGoals)


  const showErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error3' });
    }
  }






  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setInputSelect({
      ...inputSelect,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeCommissions = (event: ChangeEvent<HTMLInputElement>, data: Goal) => {

    const updatedNetARC = event.target.name === 'netArc' ? (Number.parseFloat(event.target.value) !== data.netArc ? event.target.value : data.netArc) : data.netArc
    const updatedARC = event.target.name === 'arc' ? (Number.parseFloat(event.target.value) !== data.arc ? event.target.value : data.arc) : data.arc
    const updatedChurnBreakage = event.target.name === 'churnBreakage' ? (Number.parseFloat(event.target.value) !== data.churnBreakage ? event.target.value : data.churnBreakage) : data.churnBreakage
    const newObj = {
      period: data.period,
      netArc: updatedNetARC,
      arc: updatedARC,
      churnBreakage: updatedChurnBreakage
    }

    if (newObj['churnBreakage'] === '') newObj['churnBreakage'] = ''
    if (newObj['netArc'] === '') newObj['netArc'] = ''
    if (newObj['arc'] === '') newObj['arc'] = ''

    if (input.find(item => item.period === data.period)) {
      const updatedInput = input.map(item =>
        item.period === data.period
          ? { ...item, netArc: newObj.netArc, arc: newObj.arc, churnBreakage: newObj.churnBreakage }
          : item
      )

      setInput(updatedInput)
    } else {
      setInput([...input, newObj]);

    }

  }

  const patchGoals = async () => {

    const newArray = input && input.length > 0 && input.map((goal: any) => {

      const matchingData = dataYears[0].goals.find((data: any) => data.period === goal.period);

      if (goal.netArc === '' && goal.churnBreakage === '') {
        return null;
      }

      return {
        ...goal,
        netArc: goal.netArc === '' ? matchingData?.netArc : Number.parseFloat(goal.netArc),
        arc: goal.arc === '' ? matchingData?.arc : Number.parseFloat(goal.arc),
        churnBreakage: goal.churnBreakage === '' ? matchingData?.churnBreakage : Number.parseFloat(goal.churnBreakage)
      };
    }).filter(Boolean)

    const formattedData = {
      email: dataYears[0].email,
      goals: newArray
    }
    sendPatchGoals(formattedData)
  }


  const removeAllTheValues = () => {
    setInput([])
  }

  // Generate years options
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, index) => currentYear - index)


  const downLoadTemplate = () => {
    bulkLoadTemplate(userEmail)
  }

  const isData = dataYears && dataYears.length > 0



  return (
    <>
      {isError && showErrMessage()}

      <UfinetBox>

        <UfinetSectionBox>
          <ContainerSelectAndButton>
            <ContainerSelect>
              <Form.Select size='sm' name='year' value={inputSelect.year} onChange={handleChange}>
                <option value="">{Translation('SELECT.YEAR')}</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
              <Form.Select size='sm' style={{ marginLeft: '5px' }} name='username' value={inputSelect.username} onChange={handleChange}>
                <option value="">{Translation('SELECT.USER')}</option>
                {dataUsers === undefined ? [] : dataUsers.map((item: any) => (
                  <option key={item.id} value={item.email}>
                    {item.username}
                  </option>
                ))}
              </Form.Select>
            </ContainerSelect>

            {permissions.canWrite && (
              <UfinetButton
                icon={faUpload}
                content={Translation('BULK_LOAD')}
                onClick={() => showLoadModal()}
              />
            )}
          </ContainerSelectAndButton>

          {isData && (
            <ContainerBox>
              <BoxTotal totalCommissionAmount={totalAmount} currencyCode={currencyCode} title='ANNUAL.GOAL' />
            </ContainerBox>
          )}
        </UfinetSectionBox>

        {isData &&
          <UfinetSectionBox className='mt-5 mh-100'>



            <div>

              <ContainerHeader>
                <div>{translate('HEADER.MONTH')}</div>
                <div>{translate('ARC.NET')}</div>
                <div>Arc</div>
                <div>Churn breakage</div>
              </ContainerHeader>
              {
                dataYears && dataYears.length > 0 && dataYears[0].goals?.map((item: Goal) => {

                  const showInputValue = input.find(input => input.period === item.period) ? true : false
                  const itemToShow = showInputValue ? input.find(input => input.period === item.period) : item
                  const inputValueARC = showInputValue ? itemToShow?.netArc : ''
                  const inputValueChurn_breakage = showInputValue ? itemToShow?.churnBreakage : ''
                  const inputArc = showInputValue ? itemToShow?.arc : ''

                  return (
                    <ContainerGoals>
                      <ContainerGoalMonth>

                        <div>
                          {translate(item.goalMonth)}</div>
                      </ContainerGoalMonth>

                      <div>
                        <InputGroup className="mb-3">
                          <FormControl
                            placeholder={item.netArc}
                            size='sm'
                            name='netArc'
                            disabled={item.locked}
                            value={inputValueARC}
                            // @ts-ignore
                            onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeCommissions(event, itemToShow)}
                          />
                        </InputGroup>
                      </div>
                      <div>
                        <InputGroup className="mb-3">
                          <FormControl
                            placeholder={item.arc}
                            size='sm'
                            name='arc'
                            disabled={item.locked}
                            value={inputArc}
                            // @ts-ignore
                            onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeCommissions(event, itemToShow)}
                          />
                        </InputGroup>
                      </div>
                      <div>
                        <InputGroup className="mb-3">
                          <FormControl
                            placeholder={item.churnBreakage}
                            size='sm'
                            name='churnBreakage'
                            disabled={item.locked}
                            value={inputValueChurn_breakage}
                            // @ts-ignore
                            onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeCommissions(event, itemToShow)}
                          />
                        </InputGroup>
                      </div>

                    </ContainerGoals>
                  )
                })
              }
              {dataYears && dataYears.length > 0 && (
                <ContainerButton>
                  <Button variant='primary' style={{ marginRight: '5px' }} onClick={removeAllTheValues}>{Translation('DISCHARD.CHANGES')}</Button>
                  <Button variant='primary' onClick={() => patchGoals()}>{Translation('SEND.REQUEST')}</Button>
                </ContainerButton>
              )}
            </div>
          </UfinetSectionBox>}

        <UfinetModal
          size="xl"
          show={loadModal}
          handleClose={hideModal}
          title={Translation('LOAD.TEMPLATE')}
        >
          <BulkLoadForm downLoadTemplate={downLoadTemplate} hideLoadModal={hideModal} />
        </UfinetModal>
      </UfinetBox >
    </>
  )
}

export { GoalsPage }


const ContainerBox = styled.div`
  margin-top: 10px;
  /* margin-bottom: 46px; */
`

const ContainerSelect = styled.div`
  display: flex;
  width: 400px;
`

const ContainerSelectAndButton = styled.div`  
  display: flex;
  justify-content: space-between;
`

const ContainerHeader = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 200px 200px;
  grid-template-rows: 1fr;
  gap: 5px;
  align-items: center;
  color: #a1a6c2;
  margin-bottom: 5px;
`

const ContainerGoals = styled.div`
    display: grid;
    grid-template-columns: 200px 200px 200px 200px;
    grid-template-rows: 1fr;
    gap: 5px;
    align-items: center;
`

const ContainerGoalMonth = styled.div`
  background-color: #0065a4;
  color: white;
  height: 35px;
  margin-bottom: 10px;
  border-radius: 3px;
  & div:nth-child(1){
    padding: 8px;
    display: flex;
    /* justify-content: center; */
  }
`
const ContainerButton = styled.div`
  width: 610px;
  display: flex;
  justify-content: flex-end;
`

const FormControl = styled(Form.Control)`

  &::placeholder {
    color: #0065a4;
  }
`
const UfinetSectionBoxStyled = styled(UfinetSectionBox)`
  /* max-height: none; */
  /* box-sizing: border-box !important; */
  

`
